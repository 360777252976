.integration-container {
    background-image: url(../../Images/integration-background.png);
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;

    .title {
        margin-top: 5rem;
        font-size: 40px;
        letter-spacing: 3px;
    }

    p {
        font-weight: 100;
        font-size: 20px;
    }

    img {
        margin: 8rem 0;
        width: 80%;
    }
}