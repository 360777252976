@use '../../variables' as g;

.home-splash {
    .cta-wrapper {
        color: #fff;
        h1 {
            margin-bottom: 0.25rem;
        }
        h2 {
            margin: 0;
            font-weight: 100;
        }
        h3 {
            font-weight: 0;
        }
        button {
            border-radius: 25px;
            background-color: #F36B21;
            color: #FFFFFF;
            padding: 0.75rem 0.5rem;
            width: 12rem;
            border-style: none;
        }
    }
}