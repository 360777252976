@use '../../variables'as g;

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px calc((100% - #{g.$max-width}) / 2);
    @include g.backgroundSet();
    height: 70px;

    .logo {
        margin-right: 1.75rem;
        height: 65px;
    }

    .logo-wrapper,
    .navigation {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: g.$color;
    }

    .logo-text {
        font-size: 36px;
        color: g.$color;
        text-transform: uppercase;
    }

    .nav-link {
        position: relative;
        margin: 0.25em 1.5em;
        color: g.$color;
        text-transform: uppercase;
        text-decoration: none;
    }

    .nav-link:not(:last-child)::after {
        content: "";
        border: 2px solid g.$color;
        border-width: 2px 2px 0 0;
        margin-left: 2rem;
        top: 0;
        height: 100%;
    }
}