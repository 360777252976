@use '../../variables'as g;

.benefit-section {
    background: #145C6A;
    overflow: hidden;

    .container {
        margin: 1rem auto;
        display: flex;
        max-width: g.$max-width;
        justify-content: space-evenly;
    }
}