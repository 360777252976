.benefit-card {
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem;
    padding: 0 2rem;
    border-radius: 8px;
    min-width: 13rem;
    img {
        margin: 2rem 2rem;
        width: 50%;
        height: 50%;
    }
    .title {
        font-size: 26px;
        margin: 0;
    }
    .description {
        color: #bfbfbf;
        font-size: 14px;
    }
}