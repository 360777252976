@use '../../variables'as g;

.footer {
    background: #ffffff;

    .logo {
        display: block;
        width: 128px;
        height: 114px;
        margin: 2rem auto 0;
    }

    .logo-text {
        display: block;
        width: fit-content;
        font-size: 48px;
        margin: 0px auto;
        color: g.$color;
        text-transform: uppercase;
    }

    .bottom-text {
        display: ruby-text;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .footer-links {
        border-right: 1px dotted #b4b4b4;
        color: #b4b4b4;
        margin: 2rem;
    }

    .footer-link {
        color: #b4b4b4;
        display: flex;
        margin-bottom: 0;
        cursor: pointer;
    }

    .copyright {
        color: #b4b4b4;
        margin: 0.5em 1.5em;
        margin-top: 0;
    }

    .footer-link {
        text-decoration: none;
        color: #b4b4b4;
        margin: 0.5em 1.5em;
        text-align: center;
    }
}