@use '../../variables'as g;

.home-splash {
    background-image: url(../../Images/background-top.png);
    background-repeat: no-repeat;
    background-size: 100%;
    overflow: hidden;

    .container {
        margin: 7rem auto 8rem;
        display: flex;
        max-width: g.$max-width;
        justify-content: space-around;
        align-items: center;
    }

    .image-container {
        margin: 0 auto;
        display: flex;
        max-width: g.$max-width;
        justify-content: center;
        align-items: center;
    }
}