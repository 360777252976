@use '../../variables' as g;

.pip {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: #eee;
    border: 2px solid #aaa;
    border-radius: 16px;
}

.current-pip {
    background: g.$color;
}