@font-face {
  font-family: Raleway;
  src: url('Fonts/Raleway-Light.ttf');
}

* {
  font-family: Raleway;
}

body {
  margin: 0;
  background: #ffffff;
}

.main-bg {
  background-image: url('Images/main-bg.jpg');
  background-position-y: -291px;
}

.container {
  width: 70%;
  margin: auto;
  margin-top: 5em;
}