$small-screen: 320px;
$medium-screen: 769px;
$large-screen: 1024px;
$max-width: 100%;
$color: #4abdac;
$backgrounds: (light: #ffffff,
    dark: #333333);

@mixin backgroundSet() {
    background: map-get($backgrounds, 'light');
    // @media (prefers-color-scheme: dark) {
    //     background: map-get($backgrounds, 'dark');
    // }
}