@use '../../variables'as g;

.highlight {
    color: g.$color;
}

.bold {
    font-weight: bold;
}

.product-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    max-width: g.$max-width;
    margin: 0px auto;
    overflow: hidden;
}

.product-section.product-left {
    flex-direction: row-reverse;
}

.product-copy {}

.product-name {
    background: linear-gradient(#000, g.$color);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    border-bottom: 1px solid g.$color;
    font-size: 56px;
    font-weight: normal;
}

.product-name-section {
    background: linear-gradient(#000, g.$color);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.product-points {
    list-style: none;
}

.product-heading {
    color: g.$color;
    font-size: 28px;
    font-weight: normal;
}

.product-point {
    margin-bottom: 1em;
    font-size: 20px;
}

.product-point::before {
    content: '\25cf';
    color: g.$color;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

@media (max-width: 1200px) {
    .product-section {
        justify-content: space-between;
        padding: 40px;
        display: block;
    }

    .product-name {
        font-size: 40px;
    }

    .benefit-section .benefit-card {
        min-width: auto;
    }

    .image-wrapper .bullhorn-app {
        width: 100%;
    }

    .navbar .navigation {
        display: none;
    }

    .product-copy {
        display: block;
        width: 100%;
    }

    .product-image {
        width: 100%;
        display: block;
    }

    .home-splash {
        background-size: 800%;
    }

    .home-splash .container {
        padding-left: 40px;
        padding-right: 40px;
        display: block;
    }

    #root .integration-container img {
        width: 90%;
    }

    .image-container img {
        margin: auto;
        display: block;
        width: 100%;
    }

    #root .benefit-section .container {
        display: block;
    }

    .benefit-section .benefit-card {
        width: auto;
    }
}